import React from "react";
import "./style.css";
let items = [
    {
        fa: "fa fa-map-marker",
        title: "Company Location",
        para: "Yonkers, NY, USA"
    },
    {
        fa: "fa fa-envelope",
        title: "Write to us at",
        para: "hello@getparagon.app"
    }
]

const Footer = ({ featuresRef, registerRef, screenShotRef, pricingRef, itemRef }) => {

    const scrollToItem = (top) => {
        window.scrollTo({
            top: top ? top : 0,
            left: 0,
            behavior: 'smooth'
        });

    }

    return <>
        <div ref={itemRef} className="col-12 footer-wrapper d-flex flex-column align-items-center">
            <div className="col-lg-9 col-12 d-flex flex-row justify-content-between flex-wrap">
                <div className="col-lg-4 col-12 d-flex flex-column p-4">
                    <h4>Get In Touch</h4>
                    {items.map((i, k) => {
                        return <div key={k} className="d-flex flex-row align-items-start mt-4">
                            <div className="outline">
                                <span className={i.fa}></span>
                            </div>
                            <div className="d-flex flex-column flex-grow">
                                <h5>{i.title}</h5>
                                <h6>{i.para}</h6>
                            </div>
                        </div>
                    })}
                </div>
                <div className="col-lg-3 col-12 d-flex flex-column mt-lg-0 mt-4 p-4">
                    <h4>Quick Links</h4>
                    <p className="mt-4" onClick={e => scrollToItem(0)} style={{ cursor: 'pointer' }}>Home</p>
                    <p onClick={e => scrollToItem(featuresRef && featuresRef.current && featuresRef.current.offsetTop ? featuresRef.current.offsetTop : 0)} style={{ cursor: 'pointer' }}>Features</p>
                    <p onClick={e => scrollToItem(screenShotRef && screenShotRef.current && screenShotRef.current.offsetTop ? screenShotRef.current.offsetTop : 0)} style={{ cursor: 'pointer' }}>Screenshots</p>
                    {/* <p onClick={e => scrollToItem(registerRef && registerRef.current && registerRef.current.offsetTop ? registerRef.current.offsetTop : 0)} style={{ cursor: 'pointer' }}>Register</p> */}
                    <p onClick={e => scrollToItem(pricingRef && pricingRef.current && pricingRef.current.offsetTop ? pricingRef.current.offsetTop : 0)} style={{ cursor: 'pointer' }}>User Tiers</p>
                </div>
                {false && <div className="col-lg-3 col-12 d-flex flex-column mt-lg-0 mt-4 p-4">
                    <h4>Social Pages</h4>
                    <p className="mt-4" onClick={e => window.open('')} style={{ cursor: 'pointer' }}>Facebook</p>
                    <p onClick={e => window.open('')} style={{ cursor: 'pointer' }}>Twitter</p>
                    <p onClick={e => window.open('')} style={{ cursor: 'pointer' }}>Instagram</p>
                    <p onClick={e => window.open('')} style={{ cursor: 'pointer' }}>Linkedin</p>
                </div>}
            </div>
            <div style={{ width: '100%', height: '1px', marginTop: '60px', backgroundColor: 'rgb(238, 238, 238)' }}></div>
            <div className="col-12 d-flex flex-column align-items-center" style={{ padding: '18px 0px 6px 0px', minHeight: '0vh' }}>
                <p>&#169; Copyright 2021 @ Parle Inc</p>
            </div>
        </div>

    </>
};

export default Footer;