import React, { useState } from 'react';
import Navbar from '../../../components/navbar';
import Mobile from '../../../assets/5.png';
import MobileD from '../../../assets/d5.png';
import './style.css';

const fS = {
  position: 'fixed',
  zIndex: '9999',
  minHeight: '100vh',
  backgroundColor: '#000',
  top: '0',
  left: '0',
  opacity: '0.8',
  minWidth: '100vw',
};

const pS = {
  position: 'fixed',
  zIndex: '99999',
};

const Home = ({ dark, featuresRef, registerRef, contactRef, screenShotRef, pricingRef }) => {
  const [isModal, setModal] = useState(false);

  // const scrollToItem = (top) => {

  //     window.scrollTo({
  //         top: top ? top : 0,
  //         left: 0,
  //         behavior: 'smooth'
  //     });
  // }

  return (
    <div className='col-12 p-0 home-wrapper d-flex flex-column align-items-center'>
      <Navbar
        dark={dark}
        featuresRef={featuresRef}
        registerRef={registerRef}
        contactRef={contactRef}
        screenShotRef={screenShotRef}
        pricingRef={pricingRef}
      />
      <div className='effect-wrap'>
        {/* <div className='fa fa-plus effect effect-1'></div>
        <div className='fa fa-plus effect effect-2'></div> */}
        <div className='fa fa-circle-o effect effect-3'></div>
      </div>
      <div className='d-flex flex-row flex-wrap align-items-center col-lg-8 col-11  home-sub-wrapper'>
        <div className='col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center mb-lg-0 mb-4'>
          <h2 className='title'>Grow and Engage <br/> Your Group or Digital Community with Paragon.</h2>
          <p className='body-text'>
            Step into the future of community building with Paragon, where we're reimagining how groups, teams, thought leaders, and creators come together to inspire, organize, and connect. Our
            platform is your all-in-one solution to the problem of fragmented communication tools, designed to bring your community's vibrant interactions, collaboration, and impactful storytelling to
            life.
            <br /> <br />
            Join our exclusive beta program and shape the future of Paragon. Your input will directly influence how we tailor Paragon to be the perfect collaboration and community building tool. 
            <br/> <br/> Enter the
            Paraverse now and let's build something extraordinary together.
          </p>

          <div className='col-12 d-flex flex-row mt-4'>
      
            <a href='https://r8mvl63zggx.typeform.com/to/gtEs48ji'>
              <button className='btn btn-download'>Join Beta</button>
            </a>
            {false && (
              <button
                className='btn btn-play'
                onClick={e => setModal(true)}
              >
                <span className='fa fa-play'></span>
              </button>
            )}
          </div>
        </div>
        <div className='col-lg-6 col-12 mt-lg-0 mt-4 home-img-wrapper d-flex align-items-center justify-content-center'>
          <div className='home-img'>
            <div className='circle'></div>
            <div className='small-circle'></div>
            <img
              src={dark ? MobileD : Mobile}
              alt='mobile'
            />
          </div>
        </div>
      </div>
      {isModal && (
        <>
          <div
            style={fS}
            onClick={e => setModal(false)}
          />
          <div
            className='col-lg-8 col-10 p-0 modalDiv'
            style={pS}
          >
            <iframe
              title='File-O Video'
              style={{ width: '100%', minHeight: '70vh', boxShadow: `0 1px 6px 10px rgba(0, 0, 0, 0.04), 0 1px 6px 10px rgba(0, 0, 0, 0.15)` }}
              src='https://www.youtube.com/embed/an0_u96ac8A'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; 
                            gyroscope; picture-in-picture'
              allowFullScreen={true}
            ></iframe>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
