import { initializeApp } from 'firebase/app';

let config = {
    apiKey: "AIzaSyD0KmBJjU7L9BCFuIOs1rGjkkloPWUoebA",
    authDomain: "paragon-prod.firebaseapp.com",
    databaseURL: "https://paragon-prod-default-rtdb.firebaseio.com",
    projectId: "paragon-prod",
    storageBucket: "paragon-prod.appspot.com",
    messagingSenderId: "855015964640",
    appId: "1:855015964640:web:5e65a26d64048783393585",
    measurementId: "G-6E048MSTL1"
};

const app = initializeApp(config);


export default app;
