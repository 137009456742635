import React, { useEffect, useState } from "react";
import ReactPixel from 'react-facebook-pixel';
import "./style.css";

let features = [
    {
        title: "Forever Free",
        list: [
            'Unlimited Calls, Chat & Video',
            'Curate Profile and Build your Network',
            'Share Text, Links & Media Posts',
            'Create Group Chats',
            'Send Feedback for new features and Improvements'
        ],
        price: 'Free',
        price_sub: '',
        link_name: 'Join Beta',
        href_link: 'https://r8mvl63zggx.typeform.com/to/gtEs48ji'
    },
    {
        title: "Paragon Influence",
        list: [
            'Profile Badge for Creators'
            , 'Get Posts Boosted Beyond Following'
            , 'Enhanced Profile Features'
            , 'Profile Featured On Discover Page'
            , 'Analytics Reports'
        ],
        price: 5, 
        price_sub: 'Free trial for the 1st 1000 creators selected',  
        link_name: `Get Access`,
        href_link: 'https://r8mvl63zggx.typeform.com/to/g96MOr4y'
    },
];



    export const MetaPixelBetaClickTracking = () => {  
       
      ReactPixel.track('Pricing_View_Beta', {
        buttonName: 'Join Beta',
        Action: 'Clicked',
        Time: Date.now()
      });
    }

    export const MetaPixelAccessClickTracking = () => {   
      ReactPixel.track('Pricing_View_Access', {
        buttonName: 'Get Access',
        Action: 'Clicked',
        Time: Date.now()
      });
    }


const Pricing = ({ itemRef }) => {


    useEffect(()=> {
      const options = {
        autoConfig: true,  // set to false if you want to manually call `sendPageView` instead
        debug: false,       // enable logs for debugging
      };
      ReactPixel.init('2143277002671593', null, options);
    })

    // const scrollToItem = (top) => {
    //     window.scrollTo({
    //         top: top ? top : 0,
    //         left: 0,
    //         behavior: 'smooth'
    //     });

    // };

    // const scrollToItemInterested = (top) => {
    //     window.scrollTo({
    //         top: top && (top - 494 - 206) > 0 ? (top - 494 - 206) : 0,
    //         left: 0,
    //         behavior: 'smooth'
    //     });

    // };


    const [i, setI] = useState(0);

    return <div ref={itemRef} className="col-12 pricing-wrapper d-flex flex-column align-items-center">
        <h1 className="pricing-wrapper-title">User <span className="day-color">Tiers</span></h1>
        <div className="col-lg-9 col-12 d-flex flex-row flex-wrap justify-content-center mt-4">
            {features.map((item, k) => {
                return (
                  <div
                    className={`col-lg-4 p-4 col-12 mb-lg-0 mb-lg-4 d-flex`}
                    key={k}
                  >
                    <div className='pricing-card col-12 d-flex flex-column align-items-center'>
                      <h4>{item.title}</h4>
                      <div className='line'></div>
                      <div
                        className='d-flex flex-column align-items-center mt-4 mb-2'
                        style={{ width: '100%', padding: '0px 30px' }}
                      >
                        <div className='d-flex flex-row justify-content-center col-12 p-0 '>
                          {item.price !== 'Free' && <h6 style={{ marginRight: '6px', marginTop: '18px', fontWeight: '600' }}>$</h6>}
                          <h3>{item.price}</h3>
                          {item.price !== 'Free' && <h6 className = "pricing-month" style={{ marginTop: 'auto', marginLeft: '6px', marginBottom: '24px', fontWeight: '600' }}>/Month</h6>}
                        </div>
                        <div className='d-flex flex-row justify-content-center col-12 p-0'>
                          {item.price_sub ? (
                            <h6 style={{ marginTop: 'auto', marginLeft: '6px', fontSize: '14px', marginBottom: '18px', fontWeight: '400', color: '#c0392b' }}>{item.price_sub}</h6>
                          ) : (
                            <div style={{ marginTop: 'auto', marginLeft: '6px', marginBottom: '18px', height: '14px' }}></div>
                          )}
                        </div>
                      </div>
                      {item.list.map((j, key) => {
                        return (
                          <div
                            key={key}
                            className='d-flex flex-column mt-4 mb-2'
                            style={{ width: '100%', padding: '0px 30px' }}
                          >
                            <div className='col-12 d-flex flex-row align-items-center'>
                              <span className='fa fa-check'></span>
                              <h6>{j}</h6>
                            </div>
                            <div className='line mt-1'></div>
                          </div>
                        );
                      })}
                      <div className='mt-auto'></div>
                      <div
                        type='button'
                        className='mt-4'
                        
                      >
                        {item.href_link !== '' ? <a
                          className='text-decoration-none btn btn-download'
                          onClick={MetaPixelBetaClickTracking}
                          href={item.href_link}
                        >
                          {item.link_name}
                        </a> : (
                          <button
                            
                            className='btn btn-download'
                            onClick={MetaPixelAccessClickTracking}
                          >{item.link_name}</button>
                        )}
                      </div>
                    </div>
                  </div>
                );
            })}
        </div>
        <div className="d-flex flex-row">
            <div onClick={e => setI(0)} className={`${i === 0 ? 'active' : 'inactive'} dash`}></div>
            <div onClick={e => setI(4)} className={`${i === 4 ? 'active' : 'inactive'} dash`}></div>
        </div>
    </div>
};

export default Pricing;