import React, { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import './style.css';

let features = [
  {
    fa: 'fa fa-2x fa-users',
    title: 'Build',
    para: 'Grow vibrant communities around your cause, brand, or content, and engage with your audience like never before. Create Channels to share share updates with your supporters and spark dynamic conversations that your followers care about.',
    link: ''
  },
  {
    fa: 'fa fa-2x fa-handshake-o',
    title: 'Collaborate',
    para: 'Create structured workspaces to keep your conversations flowing and your team focused. Segment conversations into rooms based on topic to keep your communications organized and structured.',
    link: ''
  },
  {
    fa: 'fa fa-2x fa-bolt',
    title: 'Influence',
    para: 'For creators and thought leaders eager to reach out to new audiences, our platform amplifies your content and ideas and gets it in front of users who will appreciate it. ',
    link:  "I'm interested",
    href_link: ''
  },
  {
    fa: 'fa fa-2x fa-rocket',
    title: 'Feature 4',
    para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pellentesque, turpis placerat eleifend fringilla, lacus ex molestie mi.',
  },
  {
    fa: 'fa fa-2x fa-bus',
    title: 'Feature 5',
    para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pellentesque, turpis placerat eleifend fringilla, lacus ex molestie mi.',
  },
  {
    fa: 'fa fa-2x fa-pencil',
    title: 'Feature 6',
    para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pellentesque, turpis placerat eleifend fringilla, lacus ex molestie mi.',
  },
];

const Features = ({ pricingRef, itemRef }) => {
  const [i, setI] = useState(0);




  const MetaPixelInterestedClickTracking = () => {
    ReactPixel.track('Im Interested', {
      buttonName: 'Im Interested'
    })
  }

  const scrollToItem = (top) => {

  var currentWindowSize = window.innerWidth;
  var newMobileOffset = 800;
  const display_lg_breakpoint = 992;

    if(currentWindowSize >= display_lg_breakpoint){
      window.scrollTo({
      top: top ? top : 0,
      left: 0,
      behavior: 'smooth'
    });
      
    } else if (currentWindowSize < display_lg_breakpoint){
      window.scrollTo({
      top: top ? top + newMobileOffset : 0,
      left: 0,
      behavior: 'smooth'
  });

    }



  }

  const scrollToItemInterested = (top) => {
    window.scrollTo({
        top: top && (top - 494 - 206) > 0 ? (top - 494 - 206) : 0,
        left: 0,
        behavior: 'smooth'
    });

};

const scrollFunction = (key) => {
  key === 1
  ? scrollToItemInterested(itemRef && itemRef.current && itemRef.current.offsetTop ? itemRef.current.offsetTop : 0) 
  : scrollToItem(pricingRef && pricingRef.current && pricingRef.current.offsetTop ? pricingRef.current.offsetTop : 0);

}

  return (
    <div
      ref={itemRef}
      className='col-12 feature-wrapper d-flex flex-column align-items-center'
    >
      <h1 className='feature-wrapper-title'>
        The Paragon <span className='day-color'>Difference</span>
      </h1>
      <div className='col-lg-9 col-12 d-flex flex-row flex-wrap mt-4'>
        {features.map((item, k) => {
          return (
            <div
              className={`col-lg-4 p-4 col-12 mb-lg-0 mb-lg-4`}
              key={k}
              style={{ display: k >= i && k <= i + 2 ? 'flex' : 'none' }}
            >
              <div className='feature-card col-12 d-flex flex-column align-items-center'>
                <div className='wrapper-card'>
                  <span className={item.fa}></span>
                </div>
                <h4 className='mt-4'>{item.title}</h4>
                <p className='mt-2'>{item.para}</p>
                
                {item.link !== '' ? 
                  <div className="btn btn-submit mt-4 feature-button" 
                  onClick={e => (scrollFunction(),MetaPixelInterestedClickTracking())
                    
                  }
                  >{item.link}</div> : ''}
              </div>
            </div>
          );
        })}
      </div>
      <div className='d-flex flex-row'>
        <div
          onClick={e => setI(0)}
          className={`${i === 0 ? 'active' : 'inactive'} dash`}
        ></div>
        {false && (
          <div
            onClick={e => setI(3)}
            className={`${i === 3 ? 'active' : 'inactive'} dash`}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Features;
