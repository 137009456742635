import React from "react";
import "./style.css";

let features = [
    {
        title: "Download",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pellentesque, turpis placerat eleifend fringilla, lacus ex molestie mi."
    },
    {
        title: "Create Profile",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pellentesque, turpis placerat eleifend fringilla, lacus ex molestie mi."
    },
    {
        title: "Search Profile",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pellentesque, turpis placerat eleifend fringilla, lacus ex molestie mi."
    },
    {
        title: "Place Order",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pellentesque, turpis placerat eleifend fringilla, lacus ex molestie mi."
    }
];

let arrItems = [1, 2, 3, 4];

const Working = () => {

    return <div className="col-12 working-wrapper d-flex flex-column align-items-center">
        <h1>How it <span className="day-color">Works</span></h1>
        <div className="col-lg-9 col-12 d-flex flex-row flex-wrap mt-4 align-items-center display-none-sm">
            {arrItems.map((item, k) => {
                return <div className="col-lg-3 d-flex flex-row justify-content-center align-items-center p-0" style={{ position: 'relative' }} key={k}>
                    {k !== 0 && <div className="line1"></div>}
                    <h6 className="work-circle">{item}</h6>
                    {k !== 3 && <div className="line2"></div>}
                </div>
            })}
        </div>
        <div className="col-lg-9 col-12 d-flex flex-row flex-wrap mt-lg-0 mt-4">
            {features.map((item, k) => {
                return <div className={`col-lg-3 p-4 col-12 mb-lg-0 mb-4`} key={k}>
                    <div className="working-card col-12 d-flex flex-column align-items-center">
                        <h6 className="work-circle display-none-lg">{k + 1}</h6>
                        <h4 className="mt-lg-0 mt-2">{item.title}</h4>
                        <p className="mt-2">{item.para}</p>
                    </div>
                </div>
            })}
        </div>
    </div>
};

export default Working;