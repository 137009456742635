
import React, { useState, useEffect } from "react";
import ToggleIcon from './components/toggleIcon';
import app from "./utils/firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import ReactPixel from 'react-facebook-pixel';
import { MetaPixelAccessClickTracking, MetaPixelBetaClickTracking } from "./pages/main_page/pricing";
import MainPage from "./pages/main_page";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";

function App() {

  useEffect(() => {
    const analytics = getAnalytics(app);
    logEvent(analytics, 'User Visit', { value: 'Visited' });
  }, []);


  useEffect(() => {
    // Initialize Facebook Pixel
    const options = {
      autoConfig: true,  // set to false if you want to manually call `sendPageView` instead
      debug: true,       // enable logs for debugging
    };
    ReactPixel.init('2143277002671593', null, options);

    // Track page view
    ReactPixel.pageView();
    
    
    ReactPixel.track('Pricing_View_Beta', { 
      function: MetaPixelBetaClickTracking
    });

    ReactPixel.track('Pricing_View_Access', { 
      function: MetaPixelAccessClickTracking
    });


    // Add more events here if needed
  }, []);

  const [dark, setDark] = useState(true);



  return (
    <div className="container-fluid p-0" style={{ overflowX: 'hidden' }}>
      <div className={`${dark ? 'dark-theme' : 'day-theme'}`}>
        <ToggleIcon dark={dark} setDark={setDark} />
        <Router>
          <Switch>
            <Route exact path="/">
              <MainPage dark={dark} setDark={setDark} />
            </Route>
            <Route
              path="*"
              render={() => <Redirect to="/"/>}
            />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
