import React, { useState } from "react";
import "./style.css";
import Mobile1 from '../../../assets/1.png';
import Mobile2 from '../../../assets/2.png';
import Mobile3 from '../../../assets/3.png';
import Mobile4 from '../../../assets/4.png';
import Mobile5 from '../../../assets/5.png';
import Mobile6 from '../../../assets/6.png';
import Mobile7 from '../../../assets/7.png';
import Mobile8 from '../../../assets/8.png';
import Mobile12 from '../../../assets/12.png';
import Mobile17 from '../../../assets/17.png';

import MobileD1 from '../../../assets/d1.png';
import MobileD2 from '../../../assets/d2.png';
import MobileD3 from '../../../assets/d3.png';
import MobileD4 from '../../../assets/d4.png';
import MobileD5 from '../../../assets/d5.png';
import MobileD6 from '../../../assets/d6.png';
import MobileD7 from '../../../assets/d7.png';
import MobileD8 from '../../../assets/d8.png';
import MobileD12 from '../../../assets/d12.png';
import MobileD17 from '../../../assets/d17.png';

let screenshots = [];

const ScreenShots = ({ dark, itemRef }) => {

    if (!dark) {
        screenshots = [
            Mobile1,
            Mobile8,
            Mobile17,
            Mobile12,
            Mobile6,
            Mobile4,
            Mobile2,
            Mobile3,
            Mobile5,
            Mobile7,
        ];
    } else {
        screenshots = [
            MobileD1,
            MobileD8,
            MobileD17,
            MobileD12,
            MobileD6,
            MobileD4,
            MobileD2,
            MobileD3,
            MobileD5,
            MobileD7
        ];
    }
    const [i, setI] = useState(0);

    return <div ref={itemRef} className="col-12 app-wrapper d-flex flex-column align-items-center">
        <h1 className="app-wrapper-title">App <span className="day-color">Screenshots</span></h1>
        <div className="col-lg-9 col-12 d-flex flex-row flex-wrap mt-4">
            {screenshots.map((item, k) => {
                return <div className={`col-lg-3 p-4 col-12 mb-lg-0 mb-lg-4 justify-content-center align-items-center`} key={k} style={{ display: k >= i && k <= i + 3 ? 'flex' : 'none' }}>
                    <img className="app-card col-lg-12 col-8" alt="App Screenshots" src={item} />
                </div>
            })}
        </div>
        {false && <div className="d-flex flex-row">
            <div onClick={e => setI(0)} className={`${i === 0 ? 'active' : 'inactive'} dash`}></div>
            <div onClick={e => setI(4)} className={`${i === 4 ? 'active' : 'inactive'} dash`}></div>
        </div>}
    </div>
};

export default ScreenShots;