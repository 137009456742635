import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import Logo from '../../assets/logo_t.png';
import LogoW from '../../assets/logo_t_w.png';
import './style.css'

const SideNav = ({ nav, setNav, featuresRef, registerRef, contactRef, screenShotRef, pricingRef, dark  }) => {
    const scrollToItem = (top) => {
        setNav(false);
        window.scrollTo({
            top: top ? top : 0,
            left: 0,
            behavior: 'smooth'
        });

    }

    return <>
        <CSSTransition in={nav} classNames="d" timeout={{ enter: 200, exit: 100 }} unmountOnExit appear>
            <div className="sNS col-12 d-flex flex-column">
                <div className="col-12 d-flex flex-row justify-content-end">
                    <span className="fa fa-2x fa-times mt-4" onClick={e => setNav(false)} style={{ cursor:'pointer' }}></span>
                </div>
                <img src={dark ? Logo : LogoW} alt="Paragon" className="nav-logo" />
                <div className="d-flex flex-column align-items-center">
                <h6 onClick={e => scrollToItem(featuresRef && featuresRef.current && featuresRef.current.offsetTop ? featuresRef.current.offsetTop: 0)}>Features</h6>
                <h6 onClick={e => scrollToItem(screenShotRef && screenShotRef.current && screenShotRef.current.offsetTop ? screenShotRef.current.offsetTop: 0)}>Screenshots</h6>
                {/* <h6 onClick={e => scrollToItem(registerRef && registerRef.current && registerRef.current.offsetTop ? registerRef.current.offsetTop: 0)}>Register</h6> */}
                <h6 onClick={e => scrollToItem(pricingRef && pricingRef.current && pricingRef.current.offsetTop ? pricingRef.current.offsetTop: 0)}>User Tiers</h6>
                {/* <h6 onClick={e => scrollToItem(contactRef && contactRef.current && contactRef.current.offsetTop ? contactRef.current.offsetTop: 0)}>Contact</h6> */}
                </div>
            </div>
        </CSSTransition>
    </>
}

export default SideNav;