import { useRef } from "react";
import Contact from "./contact";
import DownloadApp from "./download_app";
import Features from "./features";
import Footer from "./footer";
import Home from "./home";
import Pricing from "./pricing";
import Register from "./register";
import ScreenShots from "./screenshots";
import Team from "./team";
import Working from "./working";
import LetsBuild from "./lets-build";
import Mission from "./mission";
import Vission from "./vision";

const MainPage = ({
    dark
}) => {
    const featuresRef = useRef({}),
    registerRef = useRef({}),
    contactRef = useRef({}),
    screenShotRef = useRef({}),
    pricingRef = useRef({}),
    letsBuildRef = useRef({});

    return <>
        <Home dark={dark} featuresRef={featuresRef} screenShotRef={screenShotRef} pricingRef={pricingRef} letsBuildRef={letsBuildRef} />
        <LetsBuild itemRef={featuresRef} />
        <Features pricingRef={pricingRef} itemRef={featuresRef}/>
        <ScreenShots dark={dark} itemRef={screenShotRef} />
        {false && <DownloadApp />}
        {/* <Register itemRef={registerRef} /> */}
        {false && <Working />}
        <Pricing itemRef={pricingRef} registerRef={registerRef} contactRef={contactRef} />
        {/* <Mission /> */}
        {/* <Vission /> */}
        {/* <Team /> */}
        {/* <Contact /> */}
        <Footer itemRef={contactRef} featuresRef={featuresRef} registerRef={registerRef} screenShotRef={screenShotRef} pricingRef={pricingRef} />
    </>;
};

export default MainPage;