import React, { useState } from "react";
import SideNav from "../sidenav";
import Logo from '../../assets/logo_t.png';
import LogoW from '../../assets/logo_t_w.png';
import './style.css';



const Navbar = ({ featuresRef, registerRef, contactRef, screenShotRef, pricingRef, dark }) => {
    const [nav, setNav] = useState(false);

    let Logo_Edit = <img src={Logo} alt="Paragon" className="nav-logo Logo p-3" />
    let LogoW_Edit = <img src={LogoW} alt="Paragon" className="nav-logo LogoW p-1"/>



    const scrollToItem = (top) => {

        window.scrollTo({
            top: top ? top : 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    return <div className="col-12 navbar">
        <div className="col-lg-11 col-12 d-flex flex-row align-items-center justify-content-between">
            {dark ? Logo_Edit : LogoW_Edit}
            <div className="d-flex flex-row align-items-center display-sm-none">
                <h6 onClick={e => scrollToItem(featuresRef && featuresRef.current && featuresRef.current.offsetTop ? featuresRef.current.offsetTop : 0)}>Features</h6>
                <h6 onClick={e => scrollToItem(screenShotRef && screenShotRef.current && screenShotRef.current.offsetTop ? screenShotRef.current.offsetTop : 0)}>Screenshots</h6>
                {/* <h6 onClick={e => scrollToItem(registerRef && registerRef.current && registerRef.current.offsetTop ? registerRef.current.offsetTop : 0)}>Register</h6> */}
                <h6 onClick={e => scrollToItem(pricingRef && pricingRef.current && pricingRef.current.offsetTop ? pricingRef.current.offsetTop : 0)}>User Tiers</h6>
                {/* <h6 onClick={e => scrollToItem(contactRef && contactRef.current && contactRef.current.offsetTop ? contactRef.current.offsetTop : 0)}>Contact</h6> */}
            </div>
            <div className="btn-ham display-lg-none" style={{ cursor: 'pointer' }} onClick={e => setNav(true)}>
                <span className="fa fa-bars fa-2x mx-3"></span>
            </div>
            {nav && <SideNav dark={dark} featuresRef={featuresRef} registerRef={registerRef} contactRef={contactRef} screenShotRef={screenShotRef} pricingRef={pricingRef} nav={nav} setNav={setNav} />}
        </div>
    </div>
};

export default Navbar;