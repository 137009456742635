import React from "react";
import "./style.css";

const LetsBuild = ({ itemRef }) => {

    return <div ref={itemRef} className="col-12 lets-build-wrapper d-flex flex-column align-items-center text-center">
        <h1 className="lets-build-title">Where Interactions Inspire Innovation</h1>
        <p className="lets-build-body" style={{
            marginTop: '42px'
        }}> At Paragon, we believe in the power of community. We provide the tools for creators and <br/>
        leaders like you to build and manage your online community, fostering deeper connections that help you build the future that you want to see.</p>
       
    </div>
};

export default LetsBuild;